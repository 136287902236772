.contact-form {
  position: relative;

  @media (--mobile) {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.contact-form__wrapper {
  padding: 100px;

  border-radius: 50px;
  background-color: #2c2c2c;

  @media (--mobile) {
    padding: 20px 20px 50px;

    border-radius: 0;
  }
}

.contact-form__label {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;

  position: absolute;
  top: 50px;
  right: 0;

  display: flex;
  align-items: center;

  height: 25px;
  padding-right: 10px;

  color: var(--color-primary);
  background-color: #0054d7;

  @media (--mobile) {
    position: static;

    margin-bottom: 25px;
  }
}

.contact-form__label img {
  margin-right: 9px;
  margin-left: 6px;
}

.contact-form__content {
  width: 100%;
  height: 100%;
}

.contact-form__header {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;

  display: block;

  margin-bottom: 20px;

  color: var(--color-yellow);
}

.contact-form__subtitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;

  display: block;

  margin-bottom: 20px;

  @media (--mobile) {
    margin-bottom: 25px;
  }
}

.contact-form__divider {
  display: flex;
  align-items: center;

  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;

  color: #444;
  column-gap: 10px;
}

.contact-form__divider::before,
.contact-form__divider::after {
  flex-grow: 1;

  content: '';

  border-top: 1px solid #373737;
}

.contact-form__schedule {
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  padding: 10px 50px;

  text-decoration: none;
  letter-spacing: 0;

  color: inherit;
  border: 1px solid #373737;
  gap: 10px;
}
