.rates {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 150px;
  padding-bottom: 150px;

  border-radius: 50px;
  background-color: #2c2c2c;
  row-gap: 50px;

  @media (--mobile) {
    margin-right: -15px;
    margin-left: -15px;
    padding: 50px 15px;

    border-radius: 0;
    row-gap: 20px;
  }
}

.rates__calculator {
  width: 190px;
  height: 150px;

  @media (--mobile) {
    width: 100px;
    height: 80px;
  }
}

.pricing__title {
  text-align: center;
}

.rates__optal {
  color: #0d55ce;
}

.rates__uring {
  color: #875782;
}

.rates__schedule {
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 390px;
  height: 50px;
  padding: 10px 50px;

  text-decoration: none;
  letter-spacing: 0;

  color: inherit;
  border: 1px solid #373737;
  gap: 10px;

  @media (--mobile) {
    width: 100%;
  }
}
