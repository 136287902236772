.grid-container {
  @media (--tablet) {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 90px);
    gap: 10px;

    justify-content: center;
  }
}

.grid-container .item-1 {
  @media (--tablet) {
    grid-column: auto / span 1;
  }
}

.grid-container .item-2 {
  @media (--tablet) {
    grid-column: auto / span 2;
  }
}

.grid-container .item-3 {
  @media (--tablet) {
    grid-column: auto / span 3;
  }
}

.grid-container .item-4 {
  @media (--tablet) {
    grid-column: auto / span 4;
  }
}

.grid-container .item-5 {
  @media (--tablet) {
    grid-column: auto / span 5;
  }
}

.grid-container .item-6 {
  @media (--tablet) {
    grid-column: auto / span 6;
  }
}

.grid-container .item-7 {
  @media (--tablet) {
    grid-column: auto / span 7;
  }
}

.grid-container .item-8 {
  @media (--tablet) {
    grid-column: auto / span 8;
  }
}

.grid-container .item-9 {
  @media (--tablet) {
    grid-column: auto / span 9;
  }
}

.grid-container .item-10 {
  @media (--tablet) {
    grid-column: auto / span 10;
  }
}

.grid-container .item-11 {
  @media (--tablet) {
    grid-column: auto / span 11;
  }
}

.grid-container .item-12 {
  @media (--tablet) {
    grid-column: auto / span 12;
  }
}

.grid-container .start-1 {
  @media (--tablet) {
    grid-column-start: 1;
  }
}

.grid-container .start-2 {
  @media (--tablet) {
    grid-column-start: 2;
  }
}

.grid-container .start-3 {
  @media (--tablet) {
    grid-column-start: 3;
  }
}

.grid-container .start-4 {
  @media (--tablet) {
    grid-column-start: 4;
  }
}

.grid-container .start-5 {
  @media (--tablet) {
    grid-column-start: 5;
  }
}

.grid-container .start-6 {
  @media (--tablet) {
    grid-column-start: 6;
  }
}

.grid-container .start-7 {
  @media (--tablet) {
    grid-column-start: 7;
  }
}

.grid-container .start-8 {
  @media (--tablet) {
    grid-column-start: 8;
  }
}

.grid-container .start-9 {
  @media (--tablet) {
    grid-column-start: 9;
  }
}

.grid-container .start-10 {
  @media (--tablet) {
    grid-column-start: 10;
  }
}

.grid-container .start-11 {
  @media (--tablet) {
    grid-column-start: 11;
  }
}

.grid-container .start-12 {
  @media (--tablet) {
    grid-column-start: 12;
  }
}
