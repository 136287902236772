.page-header {
  margin-bottom: 100px;

  @media (--mobile) {
    margin-bottom: 60px;
  }
}

.page-header__logo-block {
  display: flex;
  align-items: center;
}

.page-header__logo {
  margin-right: 15px;
}

.page-header__company-name {
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: var(--line-height-s);

  color: #fff;
}

.page-header__slogan {
  font-size: var(--font-size-m);
  font-weight: 300;
  line-height: var(--line-height-s);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (--mobile) {
    display: none;
  }
}

.page-header__nav {
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: var(--line-height-s);

  display: flex;
  align-items: center;

  margin-left: auto;

  color: #fff;

  @media (--mobile) {
    display: none;
  }
}

.page-header__nav__item {
  text-decoration: none;

  color: inherit;
}

.page-header__nav__item:not(:first-child) {
  margin-left: 30px;
}

.page-header__links {
  display: flex;

  height: 20px;
}

.page-header__links__item {
  display: flex;
  align-items: center;

  color: var(--color-primary);
  text-underline-offset: 2px;
}

.page-header__links__item__icon {
  width: 8px;
  height: 8px;
}

.page-header__links__item__logo {
  width: 15px;
  height: 15px;
}

.page-header__links__item *:not(:last-child) {
  padding-right: 5px;
}

.page-header__links__item:not(:first-child) {
  display: flex;
  align-items: center;

  margin-left: 30px;
}
