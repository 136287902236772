.applicationForm__steps {
  width: 100%;
}

.hs-form-field:not(:last-child) {
  margin-bottom: 20px;
}

.hs-form-field input,
.hs-form-field textarea {
  width: 100%;
}

.hs-form-field textarea {
  height: 150px;
}

.hs-error-msgs {
  list-style-type: none;
}

.hs-error-msg {
  color: var(--color-error);
  font-size: var(--space-s);
  line-height: var(--font-size-m);
  margin-top: var(--space-3xs);
}

.hs-button {
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;

  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  text-decoration: none;

  color: var(--bg-color-dark);
  background-color: var(--color-yellow);
  border: none;
  transition: 0.3s background-color;
}

.hs-button:hover {
  background-color: var(--color-brandeis-blue-hover);
}

.hs_error_rollup {
  display: none;
}

.actions {
  position: relative;
}

/* .hs-error-msgs {
  font-size: var(--space-s);
  line-height: var(--font-size-m);

  display: none;

  margin-top: var(--space-3xs);

  color: var(--color-error);
} */

.input__type_invalid + .applicationForm__error {
  display: block;
}

.applicationForm__successScreen {
  display: none;
}

.applicationForm__checkIcon {
  display: block;

  width: 39px;
  height: 30px;
  margin-top: var(--space-xs);
  margin-bottom: var(--space-m);
}

.applicationForm--state-success .applicationForm__form {
  display: none;
}

.applicationForm--state-success .applicationForm__successScreen {
  display: block;
}

.applicationForm__privacy {
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;

  display: block;

  margin-bottom: 20px;

  text-align: left;
  letter-spacing: 0;

  color: #515151;
}
