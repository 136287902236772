.clients__cards {
  margin-top: -10px;

  @media (--mobile) {
    display: flex;
    overflow: auto;

    column-gap: 10px;

    margin-top: 20px;
  }
}

.clients__tile {
  @media (--mobile) {
    display: flex;
    column-gap: 10px;

    margin: 0;
  }
}

.clients__header {
  @media (--mobile) {
    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
  }
}

.clients__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;

  display: block;

  margin-top: 50px;
  margin-bottom: 50px;

  @media (--mobile) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.clients__slogan {
  font-weight: 300;
}

.clients__slogan b {
  font-weight: 700;
}

.clients__link {
  display: flex;
  align-items: center;

  color: inherit;
}

.clients__link::after {
  display: block;

  width: 8px;
  height: 8px;
  margin-left: 5px;

  content: '';

  background-image: url('../assets/link-icon.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.clients__madcurve__risings {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;

  background: radial-gradient(
    167.5% 326.86% at 55.64% 7.5%,
    #87dbb7 0%,
    #1c51db 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clients__feedback-tile {
  @media (--mobile) {
    display: none;
  }
}

.clients__madcurve__feedback {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;

  position: relative;

  padding: 55px 55px 75px;

  color: #d9d9d9;
  border-radius: 25px;
  background-color: #2c2c2c;
}

.clients__review {
  margin-top: 20px;
}

.clients__rating,
.clients__service {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.clients__madcurve-cite {
  position: absolute;
  bottom: 0;
  left: -35px;
}

.clients__madcurve {
  position: relative;
}

.clients__unicorn-img {
  position: absolute;
  bottom: 50px;
  left: -200px;

  @media (--mobile) {
    display: none;
  }
}

.clients__sidekick_truvity {
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  margin-bottom: 10px;
  row-gap: 10px;

  @media (--mobile) {
    flex-direction: row;
  }
}

.clients__chainstack {
  margin-top: 90px;
}

.clients__mindbox {
  margin-top: 250px;
}

.clients__municorn_ornament {
  display: flex;
  flex-direction: column;

  margin-top: 90px;

  row-gap: 10px;

  @media (--mobile) {
    flex-direction: row;
  }
}

.clients__blinkist {
  margin-top: 250px;
}
