.mainPage {
  width: 1310px;
  margin: 0 auto;
  padding: 25px 15px 0;

  @media (--mobile) {
    width: 375px;
    padding-top: 15px;
  }
}
