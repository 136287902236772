.button {
  font: inherit;
  font-weight: 300;

  padding: var(--space-m);

  cursor: pointer;
  text-align: center;
  text-decoration: underline;

  color: var(--white);
  border: none;
  background-color: var(--color-brandeis-blue);
  box-shadow: none;

  transition: 0.3s background-color;
}

.button:hover {
  background-color: var(--color-brandeis-blue-hover);
}

.button:disabled {
  cursor: not-allowed;

  background-color: var(--color-brandeis-blue-disabled);
}

.button__size-s {
  padding: var(--space-s);
}
