.mvp {
  @media (--mobile) {
    display: none;
  }
}

.mvp__hello {
  margin-top: 50px;
}

.mvp__hello-text {
  display: flex;
  flex-direction: column;

  margin-bottom: 40px;
  margin-left: 140px;
}

.mvp__main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 790px;
  padding: 100px;

  border-radius: 50px;
  background: #2c2c2c;
  gap: 50px;
}

.mvp__main__time {
  color: #fa555b;
}

.mvp__main__consult {
  color: #87dcb7;
}

.mvp__main__estimation {
  color: #ffd576;
}

.mvp__main__buttons {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  order: 3;

  width: 590px;
  height: 50px;
  padding: 0;
  gap: 10px;
}

.mvp__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding: 0;

  list-style-type: none;
}

.mvp__list h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.mvp__description {
  font-weight: 300;
}

.mvp__main__buttons-button {
  display: flex;
  align-items: center;
  flex: none;
  flex-direction: row;
  flex-grow: 1;
  order: 0;

  width: 290px;
  height: 50px;
  padding: 10px 25px;

  color: #fff;
  border: 1px solid #373737;
  background: #2c2c2c;
  gap: 10px;
}
