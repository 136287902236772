/* Box Sizing */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Lexend, Arial, sans-serif;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);

  margin: 0;

  color: var(--color-primary);
  background-color: var(--bg-color-dark);
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul,
p {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.container {
  display: block;

  width: 100%;
  max-width: 1280px;
  margin: 0;
  margin: 0 auto;
  padding: var(--space-l) 10px;
}

.visually-hidden {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;
  clip-path: inset(100%);
}

.client-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 189px;
  padding: 20px 20px 30px;

  border: 1px solid #373737;
  border-radius: 20px;
  gap: 20px;
}

.section {
  margin-bottom: 90px;
}
