.qualities {
  @media (--mobile) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.qualities__header {
  display: grid;
  row-gap: 10px;

  margin-bottom: 20px;
}

.qualities__header_green {
  color: #87dcb7;
}

.qualities__header_yellow {
  color: #ffd576;
}

.qualities__header_red {
  color: #fa555b;
}
