.technologies__header {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 100px;

  text-align: center;
}

.technologies__main-logo {
  margin-bottom: 50px;
}

.technologies__technology-title {
  display: block;

  margin-bottom: 20px;
}

.technologies__technology-description {
  display: block;

  margin-bottom: 20px;
}

.technologies__icon {
  margin-left: 10px;
}

.technologies__list_frontend,
.technologies__list_ci {
  margin-bottom: 50px;

  @media (--mobile) {
    margin-bottom: 0;
  }
}

.technologies__list {
  display: grid;

  padding: 0;

  list-style-type: none;

  color: #fff;
  row-gap: 10px;

  @media (--mobile) {
    margin-bottom: 20px;
  }
}

.technologies__list li {
  display: flex;
  align-items: center;
}

.technologies__note {
  display: block;

  margin-top: 20px;
  margin-bottom: 20px;
}

.technologies__reward {
  display: block;

  margin-bottom: 20px;

  @media (--mobile) {
    display: none;
  }
}

.technologies__reward-title {
  width: 190px;

  text-align: center;

  @media (--mobile) {
    display: none;
  }
}
