.footer {
  margin-top: 100px;
  padding: 50px 15px 25px;

  background-color: #2b2b2b;
}

.footer__social-list {
  display: flex;
  flex-direction: column;

  margin-bottom: 30px;
  padding: 0;

  list-style-type: none;
  row-gap: 20px;
}

.footer__social-link {
  display: flex;
  align-items: center;

  margin-left: 10px;

  color: #fff;
}

.footer__social-link::after {
  display: block;

  width: 8px;
  height: 8px;
  margin-left: 10px;

  content: '';

  background-image: url('../assets/link-icon.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.footer__social-item {
  display: flex;
  align-items: center;
}

.footer__policy {
  padding-right: 20px;
}

.footer__policy a {
  color: inherit;
}

.footer__main-contacts {
  display: flex;
  flex-direction: column;

  padding: 45px 45px 55px;

  border-radius: 25px;
  background-color: #353535;
  row-gap: 20px;

  @media (--mobile) {
    display: none;
  }
}

.footer__main-contacts-row {
  display: flex;
  flex-direction: column;
}

.footer__phone-link-container {
  display: flex;
  align-items: center;
  column-gap: 5px;

  margin-bottom: 5px;
}

.footer__contacts {
  @media (--mobile) {
    display: none;
  }
}

.footer__phone-link {
  color: #d9d9d9;
}

.footer__label-red {
  position: relative;

  width: 166px;
  margin-bottom: 40px;
  padding: 10px 20px;

  border-radius: 10px;
  background-color: #fa555b;
}

.footer__label-red::after {
  position: absolute;
  top: calc(100% - 5px);
  left: 20px;

  display: block;

  content: '';

  border: 10px solid transparent;
  border-top: 10px solid #fa555b;
  border-left: 10px solid #fa555b;
}

.footer__label-blue {
  position: relative;

  display: inline-block;

  width: 98px;
  margin-top: 5px;
  margin-bottom: 35px;
  margin-left: -52px;
  padding: 10px 20px;

  border-radius: 25px;
  background-color: #1c51db;
}

.footer__label-blue::after {
  font-size: 25px;

  position: absolute;
  top: calc(100% - 10px);
  right: 10px;

  display: block;

  content: '▼';

  color: #1c51db;
}

.footer__company-address {
  font-style: normal;

  display: flex;
  flex-direction: column;
  row-gap: 5px;

  margin-bottom: 25px;
}

.footer__company-address b {
  font-weight: 700;
}

.footer__hire-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  padding: 0;

  list-style-type: none;
}

.footer__hire-list a {
  color: #fff;
}

.footer__hire-list li {
  position: relative;

  padding-left: 10px;
}

.footer__hire-list li::before {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 5px;
  height: 5px;

  content: '';

  border-radius: 100%;
}

.footer__hire-list_dot_red li::before {
  background-color: #f9555a;
}

.footer__hire-list_dot_blue li::before {
  background-color: #1c51db;
}

.footer__bottom-row {
  margin-top: 40px;

  @media (--mobile) {
    margin-top: 50px;
  }
}
