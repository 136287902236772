.introduction__slogan {
  font-size: 50px;
  font-weight: 300;
  line-height: 60px;

  margin-bottom: 20px;
}

.introduction__slogan b {
  font-weight: 700;
}

.introduction__decorate-slogan-img {
  display: block;

  margin-bottom: 100px;
  margin-left: auto;

  @media (--mobile) {
    margin-bottom: 20px;
  }
}

.introduction__skills {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;

  width: 290px;
  margin-left: auto;

  @media (--mobile) {
    margin-bottom: 20px;
  }
}

.introduction__skills b {
  font-weight: 700;
}

.introduction__unicorn {
  position: relative;
  z-index: 2;

  margin-top: -30px;

  @media (--mobile) {
    width: 344px;
    height: 210px;
    margin-top: 0;
  }
}
