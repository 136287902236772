@font-face {
  font-family: Lexend;
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Lexend-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Lexend;
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Lexend-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Lexend;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Lexend-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Lexend;
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Lexend-Thin.woff2') format('woff2');
  font-display: swap;
}

.weight-200 {
  font-weight: 200;
}

.weight-700 {
  font-weight: 700;
}

.bold {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
}

.small-text {
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
}

.text-1 {
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
}

.text-2 {
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;

  text-decoration-line: underline;
}

.text-3 {
  font-size: 50px;
  font-weight: 700;
  font-style: normal;
  line-height: 60px;
}

.text-4 {
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
}

.text-5 {
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  line-height: 15px;
}

.text-6 {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: 30px;
}

.text-7 {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
}

.text-giant {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;

  letter-spacing: 0;
}

.blue-green-gradient {
  background: radial-gradient(
    167.5% 326.86% at 55.64% 7.5%,
    #87dbb7 0%,
    #1c51db 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yellow-red-gradient {
  background: radial-gradient(
    103.75% 174.25% at 55.64% 7.5%,
    #fa555b 0%,
    #ffd576 100%
  );
  -webkit-text-fill-color: transparent;

  background-clip: text;
}

.purple-red-gradient {
  background: radial-gradient(
    167.5% 326.86% at 55.64% 7.5%,
    #f9555a 6.77%,
    #0054d7 84.9%
  );
  -webkit-text-fill-color: transparent;

  background-clip: text;
}

.ref {
  color: var(--color-primary);
  text-underline-offset: 2px;
}
